/* global _ */
const Fuse = require('fuse.js/dist/fuse')
const debug = require('debug')('nextplus:select-helper')

module.exports = class SelectHelper {
  constructor (
    visionService,
    model,
    key,
    type,
    options = [],
    groupedOptions = false
  ) {
    this.visionService = visionService
    this.fuse = null
    this.model = model
    this.key = key
    this.type = type
    this.groupedOptions = groupedOptions
    this.initOptions(options)
    this.initFuze(options)
  }

  initOptions (options) {
    debug('initOptions')
    this.allOptions = options.map((opt, i) => ({ ...opt, originalOrder: i }))
    this.relevantOptions = options
    if (this.groupedOptions) {
      this.optionsToDisplay = _.groupBy(
        options.map((opt, i) => {
          opt.originalOrder = i
          opt.visible = i < 30
          return opt
        }),
        'group'
      )
    } else {
      this.optionsToDisplay = options.map((opt, i) => {
        opt.originalOrder = i
        opt.visible = i < 30
        return opt
      })
    }
  }

  initFuze (options = []) {
    debug('initFuze')
    const fOpts = {
      shouldSort: true,
      includeScore: true,
      keys: ['name']
    }
    this.fuse = new Fuse(options, fOpts)
  }

  findCandidates (word) {
    debug('findCandidates')
    let results = this.fuse.search(word)
    let k = 5
    if (results.length > 5) {
      for (; k < results.length; k++) {
        if (results[k].score > 0.4) {
          break
        }
      }
    }
    results = results.splice(0, k)
    return results.filter(r => r.score < 0.00001)
  }

  getOptionsToDisplay () {
    debug('getOptionsToDisplay', this.optionsToDisplay)
    return this.optionsToDisplay
  }

  setAllOptions (options = []) {
    debug('setAllOptions')
    this.allOptions = options
    this.optionsToDisplay = options.map((option, i) => {
      option.originalOrder = i
      option.visible = i < 30
      return option
    })
    this.initFuze(options)
  }

  getAllOptions () {
    debug('getAllOptions')
    return this.allOptions
  }

  loadMore () {
    debug('loadMore')
    const limit = 20
    let counter = 0
    const optIds = this.relevantOptions.map(opt => opt.id)

    if (this.groupedOptions) {
      Object.keys(this.optionsToDisplay).forEach(key => {
        this.optionsToDisplay[key].map(opt => {
          if (counter < limit && optIds.includes(opt.id)) {
            if (!opt.visible) {
              counter++
              opt.visible = true
            }
          } else {
            opt.visible = false
          }
          return opt
        })
      })
      return this.optionsToDisplay
    } else {
      return this.optionsToDisplay.map(opt => {
        if (counter < limit && optIds.includes(opt.id)) {
          if (!opt.visible) {
            counter++
            opt.visible = true
          }
        } else {
          opt.visible = false
        }
        return opt
      })
    }
  }

  resetView () {
    debug('resetView')
    this.relevantOptions = this.allOptions
    if (this.groupedOptions) {
      let visibleCounter = 0
      Object.keys(this.optionsToDisplay).forEach(key => {
        this.optionsToDisplay[key] = this.optionsToDisplay[key].map(opt => {
          opt.order = opt.originalOrder
          return opt
        })
        this.optionsToDisplay[key] = _.sortBy(
          this.optionsToDisplay[key],
          'order'
        )
        this.optionsToDisplay[key] = this.optionsToDisplay[key].map(opt => {
          opt.visible = visibleCounter < 30
          if (opt.visible) {
            visibleCounter++
          }
          return opt
        })
      })
    } else {
      this.optionsToDisplay = this.optionsToDisplay.map((opt, i) => {
        opt.order = opt.originalOrder
        return opt
      })
      this.optionsToDisplay = _.sortBy(this.optionsToDisplay, 'order')
      this.optionsToDisplay = this.optionsToDisplay.map((opt, i) => {
        opt.visible = i < 30
        return opt
      })
    }
  }

  makeSearch (query, isOCR = false) {
    debug('makeSearch')
    const that = this
    if (!query || query === '') {
      this.resetView()
      return that.optionsToDisplay
    } else {
      if (!isOCR) {
        const searchRegex = new RegExp(query, 'i')
        let index = 1
        if (that.groupedOptions) {
          that.optionsToDisplay = {}
          this.allOptions.forEach(option => {
            const tempOption = option
            const isVisible =
              searchRegex.test(tempOption.name) ||
              (opt.displayName && searchRegex.test(opt.displayName))
            tempOption.visible = isVisible || false
            if (isVisible) {
              tempOption.order = index
              index++
            } else {
              delete tempOption.order
            }
            if (that.optionsToDisplay[tempOption.group]) {
              that.optionsToDisplay[tempOption.group].push(tempOption)
            } else {
              that.optionsToDisplay[tempOption.group] = [tempOption]
            }
          })
          that.relevantOptions = []
          Object.keys(that.optionsToDisplay).forEach(key => {
            that.optionsToDisplay[key] = _.sortBy(
              that.optionsToDisplay[key],
              'order'
            )
            that.relevantOptions.push(
              ...that.optionsToDisplay[key].filter(opt =>
                searchRegex.test(opt.name)
              )
            )
          })
        } else {
          that.optionsToDisplay = this.allOptions.map(opt => {
            const isVisible =
              searchRegex.test(opt.name) ||
              (opt.displayName && searchRegex.test(opt.displayName))
            opt.visible = isVisible
            if (isVisible) {
              opt.order = index
              index++
            } else {
              delete opt.order
            }
            return opt
          })
          that.optionsToDisplay = _.sortBy(that.optionsToDisplay, 'order')
          this.relevantOptions = this.allOptions.filter(opt =>
            searchRegex.test(opt.name)
          )
        }
      } else {
        let results = this.fuse.search(query)
        let i = 1
        if (results.length > 1) {
          for (; i < results.length; i++) {
            if (results[i].score > 0.4) {
              break
            }
          }
        }
        results = results.splice(0, i)
        const optIds = []
        const opts = []
        results.forEach(r => {
          optIds.push(r.item.id)
          opts.push(r.item)
        })
        that.relevantOptions = opts
        if (that.groupedOptions) {
          Object.keys(that.optionsToDisplay).forEach(key => {
            that.optionsToDisplay[key] = that.optionsToDisplay[key].map(opt => {
              const index = optIds.indexOf(opt.id)
              if (index > -1) {
                opt.order = index
                opt.visible = true
              } else {
                delete opt.order
                opt.visible = false
              }
              return opt
            })
            that.optionsToDisplay[key] = _.sortBy(
              that.optionsToDisplay[key],
              'order'
            )
          })
        } else {
          that.optionsToDisplay = that.optionsToDisplay.map(opt => {
            const index = optIds.indexOf(opt.id)
            if (index > -1) {
              opt.order = index
              opt.visible = true
            } else {
              delete opt.order
              opt.visible = false
            }
            return opt
          })
          that.optionsToDisplay = _.sortBy(that.optionsToDisplay, 'order')
          const candidates = results.filter(r => r.score < 0.00001)
          if (candidates.length === 1) {
            _.set(that.model, that.key, candidates[0].item.id)
            return true
          }
        }
      }
      return that.optionsToDisplay
    }
  }

  ocrCallback (words, eventObject, callback) {
    debug('ocrCallback')
    const that = this
    console.log('words', words)
    if (!_.isArray(words) && !_.isUndefined(words.text)) {
      // In case of barcode scan
      const candidates = that.findCandidates(words.text)
      if (candidates.length === 1) {
        _.set(that.model, that.key, candidates[0].item.id)
        return true
      } else {
        return words.text
      }
    } else {
      if (that.visionService.androidWithNewVersion) {
        const resultsFound = false
        for (let w = 0; w < words.length; w++) {
          const candidates = that.findCandidates(words[w])
          if (candidates.length === 1) {
            _.set(that.model, that.key, candidates[0].item.id)
            return true
          }
        }
        if (!resultsFound) {
          eventObject.returnWords = false
          eventObject.callback = callback
          that.visionService.openOCRSelectDialog(
            words,
            eventObject,
            `${that.type}_${that.key}`
          )
        }
      } else {
        return words
      }
    }
  }
}
