'use strict'
const UUID = require('uuid')
const debug = require('debug')('nextplus:select-with-search')
const SelectHelper = require('app/formly/helpers/select-helper.class.js')

/* global _ */
/** @ngInject */
function selectWithSearch ($timeout, $scope, $mdSelect, visionService) {
  $scope.hasGroupedOptions = $scope.to.hasGroupedOptions || false
  $scope.enableRemoveDisabledSelected =
    $scope.to.enableRemoveDisabledSelected || false
  let initialCheck = true
  const initialValue = _.get($scope.model, $scope.options.key)
  $scope.extraOption = $scope.to.extraOption || null
  const setOptionsToDisplay = function setOptionsToDisplay (options) {
    $scope.optionsToDisplay = options

    // Create optionsById for both grouped and non-grouped options
    if ($scope.hasGroupedOptions) {
      $scope.optionsById = Object.values(options).reduce((acc, group) => {
        group.forEach(option => {
          acc[option.id] = option
        })
        return acc
      }, {})
    } else {
      $scope.optionsById = _.keyBy(options, 'id')
    }
    $scope.$applyAsync()
  }
  debug('selectHelper', $scope.hasGroupedOptions)
  let selectHelper = new SelectHelper(
    visionService,
    $scope.model,
    $scope.options.key,
    $scope.options.type,
    $scope.to.options,
    $scope.hasGroupedOptions
  )
  debug('selectHelper end')
  let input
  $scope.searchTerm = ''

  $scope.generateUUID = () => UUID()

  $scope.checkIfToDisable = function checkIfToDisable (option) {
    if (option.notAnOption) {
      if (initialValue.includes(option.id)) {
        if ($scope.enableRemoveDisabledSelected) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }
  $scope.extraOptionClick = async function extraOptionClick (ev) {
    ev.preventDefault()
    ev.stopPropagation()
    $mdSelect.hide()
    if ($scope.extraOption && typeof $scope.extraOption.action === 'function') {
      const option = await $scope.extraOption.action($scope.model)
      if (option) {
        $scope.model[$scope.options.key] = option.id
        setSelectedOption()
      }
    }
  }
  // const setSelectedOption = async function setSelectedOption (option) {
  //   $scope.selectedOption = option
  //   const value = option[$scope.mapObject.id]
  //   _.set($scope.model, $scope.options.key, value)
  //   if (
  //     !$scope.optionsToDisplay.find(
  //       option => option[$scope.mapObject.id] === value
  //     )
  //   ) {
  //     $scope.optionsToDisplay.unshift(option)
  //   }
  //   if (typeof $scope.to.onChange === 'function') {
  //     $scope.to.onChange(value, $scope.options, $scope.model, option)
  //   }
  //   $scope.$applyAsync()
  // }

  const setSelectedOption = async function setSelectedOption () {
    const value = $scope.model[$scope.options.key]
    if (value) {
      if (Array.isArray(value)) {
        $scope.selectedOption = []
        value.forEach(value => {
          $scope.selectedOption.push($scope.optionsById[value])
        })
      } else {
        $scope.selectedOption = $scope.optionsById[value]
      }
    } else {
      $scope.selectedOption = null
    }
  }

  const runSearch = function runSearch (word) {
    debug('runSearch')
    if (input) {
      input.value = word
    }
    $scope.searchTerm = word
    $scope.debouncedFn(word, true)
  }

  $scope.ocr = (words, eventObject) => {
    debug('runSearch')
    const res = selectHelper.ocrCallback(words, eventObject, selectedWord => {
      runSearch(selectedWord)
    })
    if (res === true) {
      $scope.onSelect()
    } else {
      runSearch(res)
    }
  }

  $scope.openOcr = $event => {
    debug('openOcr')
    if ($event.target.parentNode.tagName === 'MD-SELECT-HEADER') {
      input = $event.target.parentNode.querySelector('input')
    } else {
      input = $event.target.parentNode
        .querySelector('md-select')
        .querySelector('input')
      $event.target.parentNode.querySelector('md-select').click()
    }
    visionService.sendOCRAndroidEvent(
      $scope.ocr,
      visionService.androidWithNewVersion
    )
  }

  $scope.openVisionDialog = async function openVisionDialog ($event) {
    debug('openVisionDialog')
    if (
      $scope.to.preVisionDialog &&
      typeof $scope.to.preVisionDialog === 'function'
    ) {
      $scope.to.preVisionDialog()
    }
    if (!$scope.to.disabled) {
      if ($event.target.parentNode.tagName === 'MD-SELECT-HEADER') {
        input = $event.target.parentNode.querySelector('input')
      } else {
        input = $event.target.parentNode
          .querySelector('md-select')
          .querySelector('input')
        $event.target.parentNode.querySelector('md-select').click()
      }
      await visionService.openVisionDialog({
        callback: $scope.ocr,
        returnWords: visionService.androidWithNewVersion,
        key: `${$scope.options.type}_${$scope.options.key}`
      })
    }
    if (
      $scope.to.postVisionDialog &&
      typeof $scope.to.postVisionDialog === 'function'
    ) {
      $scope.to.postVisionDialog()
    }
  }

  $scope.search = $event => {
    debug('search')
    input = $event.target
    if (input.value !== $scope.searchTerm) {
      $scope.searchTerm = input.value
      $scope.debouncedFn(input.value)
    }
  }

  $scope.$watch('to.options', function (newValue, oldValue, theScope) {
    if (!_.isEqual(newValue, oldValue)) {
      debug('options watch')
      selectHelper = new SelectHelper(
        visionService,
        $scope.model,
        $scope.options.key,
        $scope.options.type,
        $scope.to.options,
        $scope.hasGroupedOptions
      )
      debug('optionsToDisplay')
      setOptionsToDisplay(selectHelper.getOptionsToDisplay())
      // $scope.optionsToDisplay = selectHelper.getOptionsToDisplay()
      debug('optionsToDisplay END')
    }
  })
  $scope.$watch(
    `model['${$scope.options.key}']`,
    function (newValue, oldValue, theScope) {
      if (newValue) {
        setSelectedOption()
      }
    }
  )
  $scope.onSelect = () => {
    debug('onSelect')
    const currentValue = _.get($scope.model, $scope.options.key)
    if (initialCheck && _.isEqual(initialValue, currentValue)) {
      initialCheck = false
      return
    }
    if (initialCheck) {
      initialCheck = false
    }
    if (!$scope.to.multiple) {
      $mdSelect.hide()
      if (input) {
        input.value = ''
      }
      $scope.searchTerm = ''
      // $scope.optionsToDisplay = selectHelper.getAllOptions()
      $scope.$applyAsync()
    }
    setSelectedOption()
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      if ($scope.to.onChange) {
        $scope.to.onChange(currentValue, $scope.options, $scope.model)
      }
    }
  }

  $scope.clearSearchTerm = () => {
    debug('clearSearchTerm')
    if (input) {
      input.value = ''
    }
    $scope.searchTerm = ''
    $scope.makeSearch('', false)
  }

  $scope.mdSelectOnOpen = function () {
    debug('mdSelectOnOpen')
    $scope.$broadcast('$md-resize')
    return $timeout(function () {
      $scope.$broadcast('$md-resize')
    }, 100)
  }

  $scope.makeSearch = function makeSearch (query, isOCR) {
    debug('makeSearch')
    const res = selectHelper.makeSearch(query, isOCR)
    if (res === true) {
      $scope.onSelect()
    } else {
      setOptionsToDisplay(res)

      // $scope.optionsToDisplay = res
    }
    $scope.$applyAsync()
  }

  const loadMoreOptions = () => {
    debug('loadMore')
    setOptionsToDisplay(selectHelper.loadMore())

    // $scope.optionsToDisplay = selectHelper.loadMore()
  }

  $scope.loadMore = _.debounce(loadMoreOptions, 100)

  $scope.debouncedFn = _.debounce($scope.makeSearch, 200)
  setOptionsToDisplay(selectHelper.getOptionsToDisplay())
  // $scope.optionsToDisplay = selectHelper.getOptionsToDisplay()

  $scope.focusInput = function focusInput () {
    if (typeof $scope.to.onInit === 'function') {
      $scope.to.onInit($scope.to, $scope.model)
    }
    $timeout(
      () => {
        const inputElement = document.querySelector(
          '.md-select-menu-container.md-active .selectboxWithSearch input'
        )
        if (inputElement) {
          inputElement.focus()
        }
      },
      300,
      false
    )
    return Promise.resolve()
  }

  $scope.$applyAsync()
}

module.exports = selectWithSearch
