/** @ngInject */
function htmlWork ($window) {
  const escapeString = function escapeString (str) {
    return str.replace(/['"\\]/g, function (char) {
      switch (char) {
        case '"':
        case "'":
        case '\\':
          return '\\' + char // prepends a backslash to backslash, percent,
        // and double/single quotes
      }
    })
  }

  const escapeHTMLQuotes = function escapeHTMLQuotes (str = '') {
    if (str === null) str = ''
    return str.replace(/"/g, '\\&quot;').replace(/'/g, '\\&apos;')
  }

  const htmlEncode = function htmlEncode (string) {
    const ENTITY_MAP = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;',
      '`': '&#x60;',
      '=': '&#x3D;'
    }

    // eslint-disable-next-line
    return String(string).replace(/[&<>"'`=\/]/g, function (s) {
      return ENTITY_MAP[s] || s
    })
  }

  /**
   * This function is same as PHP's nl2br() with default parameters.
   *
   * @param {string} str Input text
   * @param {boolean} replaceMode Use replace instead of insert
   * @param {boolean} isXhtml Use XHTML
   * @return {string} Filtered text
   */
  const nl2br = function nl2br (str, replaceMode, isXhtml) {
    const breakTag = isXhtml ? '<br />' : '<br>'
    const replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2'
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
  }

  const textAngularSanitize = function textAngularSanitize (html) {
    return $window.DOMPurify.sanitize(html, {
      ADD_ATTR: [
        'id',
        'longdesc',
        'src',
        'ta-insert-video',
        'allowfullscreen',
        'frameborder',
        'contenteditable'
      ]
    })
  }
  return {
    textAngularSanitize,
    htmlEncode,
    escapeString,
    escapeHTMLQuotes,
    nl2br
  }
}

module.exports = htmlWork
